import React from "react"

export default function HomeSection() {
  return (
    <div className="flex flex-row gap-[100px]">
      <img src="/images/malut.png" alt="" />
      <div className="flex flex-col">
        <p className="text-[90px] font-extralight text-white">Welcome to</p>
        <p className="text-[140px] font-bold text-white">Gelora Kie Raha</p>
      </div>
    </div>
  )
}
