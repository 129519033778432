import './App.css';
import HomeSection from './components/feature/Home';

function App() {
  return (
    <main className="w-full h-screen flex justify-center items-center bg-[url('../public/images/bg.png')] bg-cover">
      <HomeSection/>
    </main>
  );
}

export default App;
